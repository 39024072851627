<template>
	<div class="page">
		<div class="w1000">
			<!-- 音频 -->
			<audio controls ref="commonaudio" class="disnone">
				<source src="" />
			</audio>
			<topHeader :isShowBack="isShowBack" @goBack="handend"></topHeader>
			<div class="top-title-col" v-if="myReadingObj">
				<span>词书：{{myReadingObj.userBooksDTO.booksDTO.title}}</span>
				<span><span style="color: #f00"></span></span>
			</div>
			<div class="flex-block" v-if="booksLesson.length>0&&!wordCard">
				<!-- 列表 -->
				<div class="word-list">
					<div class="listbox" v-for="(itemOut, listIdx) in booksLesson" :key="listIdx">
						<!-- 时间列 -->
						<div class="time-item">
							<img @click="handlechecked('lesson',listIdx,0)" v-if="!itemOut.checked" class="radio-class" src="@/assets/static/circle.png" alt="" />
							<img @click="handlechecked('lesson',listIdx,0)" v-if="itemOut.checked" class="radio-class" src="@/assets/static/check-circle-filled.png" alt="" />
							<div class="time" @click="handleopenword(listIdx)">{{itemOut.title}}</div>
							<div class="words-num" @click="handleopenword(listIdx)">
								共<span style="color: #f00">{{itemOut.wordList.length}}</span>词
							</div>
							<i class="el-icon-arrow-down" @click="handleopenword(listIdx)" v-if="itemOut.isopen"></i>
							<i class="el-icon-arrow-right" @click="handleopenword(listIdx)" v-else></i>
						</div>
						<div v-if="itemOut.isopen">
							<!--  eslint-disable  -->
							<div class="word-item" v-for="(item, index) in itemOut.wordList"
								:key="index" @click="handlechecked('word',listIdx,index)">
								<img v-if="!item.checked" class="radio-class" src="@/assets/static/circle.png" alt="" />
								<img v-if="item.checked" class="radio-class" src="@/assets/static/check-circle-filled.png" alt="" />
								<!-- 单词 -->
								<div class="word-col">
									<div>{{item.word}}</div>
									<div class="word-desc">
										<span class="desc1" v-for="(sitem, sindex) in item.definitionList"
											:key="sindex">{{ sitem.part }}
											<span class="desc2" v-for="(mitem, mindex) in sitem.means"
												:key="mindex">{{ mitem }}<span v-html="'&nbsp;'"></span>
											</span>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 选择 -->
				<div class="rightbox" v-if="!wordCard">
					<div class="conbox">
						<el-form ref="form" :label-width="formLabelWidth">
							<el-form-item label="间隔">
								<el-select v-model="interval">
									<el-option label="15秒" :value="15" />
									<el-option label="20秒" :value="20" />
									<el-option label="30秒" :value="30" />
									<el-option label="60秒" :value="60" />
								</el-select>
							</el-form-item>
							<el-form-item label="是否显示释义">
							    <el-radio-group v-model="ifdefinition">
							      <el-radio :label="false">否</el-radio>
							      <el-radio :label="true">是</el-radio>
							    </el-radio-group>
							  </el-form-item>
							<el-form-item style="margin-top: 20px">
								<el-button type="primary" @click="toLinstenWords()">开始家听</el-button>
								<el-button type="success" style="margin-left: 18px;" @click="gotojiu()">听写记录</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
			<!-- 空 -->
			<div class="data_empty_box" style="padding-top: 68px;" v-if="booksLesson.length<=0&&!wordCard">
				<div class="icon"><img class="img100" :src="require('@/assets/static/kong.png')"></img></div>
				<div class="text">暂无数据</div>
			</div>
			<!-- 空 -->
			<!-- 内容 -->
			<div class="listen_conbox" v-if="linstenobj&&wordCard">
				<div class="page-content">
					<div class="timerbox">时间：{{timetext}}</div>
					<div class="progress-out">
						<div class="progress-in" :style="{width: progresstext+'%'}"></div>
						<span class="now-num">{{linstenindex+1}}</span>
						<span class="whold-num">{{linstenlist.length}}</span>
					</div>
					<div class="word-sounds">
						<div class="linsten-btn" @click="handleplaym(wordCard.phAmMp3)" :class="{'linsten-btn-checked': isLinstening}">
							<img :src="require('@/assets/static/linsten.png')" mode=""></img>
						</div>
						<div class="mean" v-if="showMean">
							<div class="desc-item" v-for="(sitem,index) in wordCard.definitionList" :key="index" v-if="sitem.part">
								<span>{{ sitem.part }}</span>
								<span class="desc2" v-for="(mitem, mindex) in sitem.means"
									:key="mindex">{{ mitem }}<span decode="true">&emsp;</span>
								</span>
							</div>
						</div>
						<div class="mean-btn" v-else @click="showMean = true">显示释义</div>
					</div>
					<div class="submit-btn" @click="handend">结束</div>
				</div>
			</div>
		</div>
		<div class="block30"></div>
	</div>
</template>

<script>
	import {mapState,} from "vuex";
	import topHeader from "@/components/topHeader/topHeader.vue";
	import {showLoading,hideLoading} from '@/common/loading.js';
	export default {
		components: {
			topHeader,
		},
		data() {
			return {
				formLabelWidth:"128px",
				booksLesson:[],
				uid:"",
				interval:15,//间隔
				ifdefinition:false,//默认不显示
				booksLesson:[],//单元页面
				isShowBack:false,//返回
				linstenlist:[],//听写结合
				isLinstening: false,//是否播放
				showMean: false,//显示释义
				linstenobj:null,//开始听的对象
				linstenindex:0,//当前开始下标
				progresstext:0,//进度
				timer1:null,
				timer2:null,
				playtimer1:null,
				playtimer2:null,
				timetext:0,
				wordCard:null,
			};
		},
		computed: {
			...mapState(["userInfo",'myReadingObj']),
		},
		created() {
			if(this.myReadingObj){
				this.uid = this.myReadingObj.bookId
				//数据
				this.userStudyWord()
			}else{
				this.$alert('还没有选择词书，请返回首页选择词书！', '提示', {
				    confirmButtonText: '确定',
				    callback: action => {
				       this.$router.replace("/")
				    }
				});
			}
		},
		beforeDestroy() {
			// 中断定时器
			this.handleclearTimeout()
		},
		methods: {
			//听写就欧陆
			gotojiu(){
				this.$router.push({
					name:"linstenRecord",
				});
			},
			//播放音频
			playAudio(src) {
				var _this = this
				try{
					_this.$refs.commonaudio.src = src;
					_this.$nextTick(res => {
					    _this.$refs.commonaudio.load()
					    _this.$refs.commonaudio.play()
					})
				}catch(e){
					//TODO handle the exception
				}
			},
			// 跳转开始家听
			toLinstenWords() {
				var _this = this
				this.showMean = false,//显示释义
				this.linstenobj = null//开始听的对象
				this.linstenindex = 0//当前开始下标
				this.progresstext = 0//进度
				this.timer1 = null
				this.timer2 = null
				this.playtimer1 = null
				this.playtimer2 = null
				this.timetext = 0
				this.wordCard = null
				let records = this.booksLesson
				var linstenlist = []
				var params = []
				records.forEach((witem, windex) => {
					witem.wordList.forEach((item, index) => {
						if(item.checked){
							linstenlist.push(item)
							params.push({
								userUuid: _this.userInfo.uuid,
								wordId: item.wordId
							})
						}
					});
				});
				if(linstenlist.length<=0){
					this.$message.error("请勾选单元单词")
					return false;
				}
				this.linstenobj = {
					interval:this.interval,//间隔
					ifdefinition:this.ifdefinition,//默认不显示
					linstenlist:linstenlist
				}
				this.isShowBack = true
				this.initfuc(0)
				//请求保存记录
				this.$http.post('app/word/userDictationWord/saveList', params).then(function(res) {})
			},
			//初始化家听
			initfuc(index){
				this.linstenindex = index
				this.isLinstening = false
				this.linstenlist = this.linstenobj.linstenlist
				this.showMean = this.linstenobj.ifdefinition
				this.wordCard = this.linstenlist[this.linstenindex]
				this.timetext = this.linstenobj.interval
				//进度
				if(this.linstenindex!=0&&this.linstenlist.length>0){
					this.progresstext = Number(((this.linstenindex / this.linstenlist.length)  * 100).toFixed(0));
				}else{
					this.progresstext = 0
				}
				//启动自动下一个定时器
				this.timeNext()
				//播放音频
				this.playfunc()
				//定时器
				this.setTimer()
				//监听听写记录，证明听过
				this.linstenlist[this.linstenindex]["duinum"] = 1
			},
			//播放点击
			handleplaym(src){
				// 清除setTimeout设置
				if (this.playtimer1) {
				  clearTimeout(this.playtimer1);
				}
				if (this.playtimer2) {
				  clearTimeout(this.playtimer2);
				}
				this.playAudio(src)
			},
			//上一个
			lastpage(){
				// 中断定时器
				this.handleclearTimeout()
				this.initfuc(this.linstenindex - 1)
			},
			//下一个
			nextpage(){
				// 中断定时器
				this.handleclearTimeout()
				if(this.linstenindex >= this.linstenlist.length-1){
					//结束
					this.handend()
				}else{
					this.initfuc(this.linstenindex + 1)
				}
			},
			//结束
			handend(){
				// 中断定时器
				this.handleclearTimeout()
				this.showMean = false,//显示释义
				this.linstenobj = null//开始听的对象
				this.linstenindex = 0//当前开始下标
				this.progresstext = 0//进度
				this.timer1 = null
				this.timer2 = null
				this.playtimer1 = null
				this.playtimer2 = null
				this.timetext = 0
				this.wordCard = null
			},
			//清除定时器
			handleclearTimeout(){
				if (this.timer1) {
				  clearTimeout(this.timer1);
				}
				if (this.playtimer1) {
				  clearTimeout(this.playtimer1);
				}
				if (this.playtimer2) {
				  clearTimeout(this.playtimer2);
				}
				if (this.timer2) {
				  clearInterval(this.timer2);
				}
			},
			//启动定时器
			timeNext(){
				var _this = this
				this.timer1 = setTimeout(function(){
					clearTimeout(_this.timer1)
					// 清除setTimeout设置
					_this.handleclearTimeout()
					_this.nextpage()
				},_this.linstenobj.interval * 1000)
			},
			//播放音频
			playfunc(){
				var _this = this
				this.playtimer1 = setTimeout(function(){
					clearTimeout(_this.playtimer1)
					_this.playAudio(_this.wordCard.phAmMp3)
					_this.isLinstening = true
				},1000)
				//间隔八秒后播放第二次
				this.playtimer2 = setTimeout(function(){
					clearTimeout(_this.playtimer2)
					_this.playAudio(_this.wordCard.phAmMp3)
				},7000)
			},
			//倒计时
			setTimer() {
				var _this = this
			    var holdTime = this.linstenobj.interval - 1;
			    this.timer2 = setInterval(() => {
			    if (holdTime <= 0) {
			        clearInterval(_this.timer2);
			        return;
			    }
			    this.timetext = holdTime;
			    holdTime--;
			    }, 1000);
			},
			//选择打开详情
			handleopenword(index){
				let booksLesson = this.booksLesson
				booksLesson[index].isopen = !booksLesson[index].isopen
				this.booksLesson = booksLesson
			},
			//选择勾选
			handlechecked(type,index,mindex){
				let booksLesson = this.booksLesson
				//单元选择按钮
				if(type=="lesson"){
					//单选全选
					let havelist = booksLesson[index].wordList.filter(item => !item.checked);
					if(havelist.length>0){
						booksLesson[index].checked = true
						booksLesson[index].wordList.forEach((item, index) => {
							item["checked"] = true
						});
						
					}else{
						booksLesson[index].checked = false
						booksLesson[index].wordList.forEach((item, index) => {
							item["checked"] = false
						});
					}
				}else{
					//选择单词
					booksLesson[index].wordList[mindex].checked = !booksLesson[index].wordList[mindex].checked
					//检测是不是全选完了，就把单元的变成选择
					let havelist = booksLesson[index].wordList.filter(item => !item.checked);
					if(havelist.length>0){
						//还要没选的单词
						booksLesson[index].checked = false
					}else{
						booksLesson[index].checked = true
					}
				}
				this.booksLesson = booksLesson
				
			},
			//数据
			userStudyWord(){
				var _this = this
				var params = {
					currentPage: 1,
					pageSize: 8888,
					bookId:this.uid
				}
				this.$http.post('app/word/booksLesson/indexWord', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data
						records.forEach((witem, windex) => {
							witem["isopen"] = false
							witem["checked"] = false
							witem.wordList.forEach((item, index) => {
								item["definitionList"] = []
								item["checked"] = false
								try{
									item["definitionList"] = JSON.parse(item.definition)
								}catch(e){
									item["definitionList"] = []
									//TODO handle the exception
								}
							});
						});
						_this.booksLesson = records
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/css/main.scss";

	.top-title-col {
		display: flex;
		background: #e3f0ff;
		align-items: center;
		font-size: 14px;
		justify-content: space-between;
		height: 40px;
		padding-left: 16px;
		padding-right: 24px;
		border-radius: 8px;
		margin-top: 16px;
	}

	.filter-list {
		display: flex;
		flex-wrap: nowrap;
		margin: 32px 0px 0 24px;

		.checked-item {
			font-weight: bold;
			color: #222222 !important;
		}

		.filter-item {
			padding: 0 4px;
			height: 40px;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 40px;
			font-size: 16px;
			color: #666666;
			position: relative;
			margin-right: 28px;
			cursor: pointer;

			&:hover {
				color: #444;
			}
		}

		.line {
			height: 2px;
			width: 24px;
			position: absolute;
			bottom: 1px;
			left: 50%;
			transform: translateX(-50%);
			border-radius: 2px;
			background: #51d9a5;
		}
	}

	.word-list {
		max-height: 688px;
		flex: 1;
		overflow-y: auto;
		padding: 20px;
		margin-right: 10px;
		border-radius: 8px;
		box-shadow: 0px 3px 10px 0px #f8f8f8;
		flex-shrink: 0;
		height: 100%;
		.listbox{
			padding-bottom: 10px;
		}
		.time-item {
			height: 50px;
			border-bottom: solid 1px #e5e5e5;
			display: flex;
			align-items: center;
			cursor: pointer;
			transition: 0.3s all;
			color: #222;
			font-weight: bold;
			font-size: 16px;
			&:hover {
				border-bottom: solid 1px #67adff;
			}
			.time{
				flex: 1;
			}
			.words-num {
				margin-left: auto;
				margin-right: 2px;
				color: #666666;
				font-size: 14px;
				font-weight: normal;
			}
		}

		.word-checked {
			box-shadow: 0px 3px 15.5px 0px #0000001a;
			background: #fff !important;
		}

		.word-item {
			width: 100%;
			display: flex;
			align-items: center;
			height: 74px;
			margin: 16px 10px 0px 0px;
			background: #f7f7f7;
			border-radius: 8px;
			cursor: pointer;
			transition: all 0.3s;
			border: #f7f7f7 1px solid;
			padding: 0 12px;

			.word-col {
				flex: 1;
				div {
					@include text-line(1);
					font-size: 16px;
					color: #222222;
					font-weight: bold;
					&:last-child {
						margin-top: 4px;
						font-size: 12px;
						color: #999999;
						font-weight: normal;
					}
				}
				.word-desc{
					width: 100%;
				}
			}

			&:hover {
				border: #e2e2e2 1px solid;
			}
		}
		.radio-class {
			width: 28px;
			height: 28px;
			transition: all 0.3s;
			margin-right: 12px;
			&:hover {
				transform: scale(1.05);
			}
		}

		&::-webkit-scrollbar {
			width: 6px;
		}

		&:hover {
			&::-webkit-scrollbar-thumb {
				background: #cfcfcf; // 滑块颜色
			}
		}

		&::-webkit-scrollbar-thumb {
			background: #efefef; // 滑块颜色
			border-radius: 5px; // 滑块圆角
		}

		&::-webkit-scrollbar-thumb:hover {
			background: #c2c2c2; // 鼠标移入滑块变红
		}

		&::-webkit-scrollbar-track {
			border-radius: 6px; // 轨道圆角
			background-color: #f7f7f7; // 轨道颜色
		}
	}

	.flex-block {
		display: flex;
		margin-top: 16px;
		.rightbox {
			width: 558px;
			padding: 180px 60px;
			background: #f7f7f7;
			.conbox{
				padding: 60px 0;
				width: 100%;
				height: 301px;
				border-radius: 8px;
				background-color: #fff;
			}
		}
	}
	.listen_conbox{
		width: 100%;
		position: relative;
		z-index: 2;
		box-sizing: border-box;
		padding: 58px 60px;
		background: #f7f7f7;
		margin-top: 18px;
		.page-content {
			width: 100%;
			box-sizing: border-box;
			padding: 98px 108px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-radius: 8px;
			background-color: #fff;
			.timerbox{
				width: 100%;
				height: 38px;
				line-height:38px;
				text-align: center;
				color: #222222;
				font-size: 16px;
			}
			.left-arrow {
				width: 80px;
				height: 80px;
				left: -24px;
				top: calc(50% - 40px);
				position: absolute;
			}
					
			.right-arrow {
				width: 80px;
				height: 80px;
				right: -24px;
				top: calc(50% - 40px);
				position: absolute;
			}
					
			.progress-out {
				width: 100%;
				height: 12px;
				border-radius: 12px;
				position: relative;
				display: flex;
				align-items: center;
				background: #e0e0e0;
					
				.progress-in {
					border-radius: 12px;
					background: #51D9A5;
					height: 12px;
				}
					
				.now-num {
					position: absolute;
					bottom: -38px;
					left: 0;
					line-height: 28px;
					color: #222;
				}
					
				.whold-num {
					position: absolute;
					bottom: -38px;
					right: 0;
					color: #222;
					line-height: 28px;
				}
			}
					
			.word-sounds {
				display: flex;
				margin-top: auto;
				flex-direction: column;
				align-items: center;
				margin-top: 38px;
				.mean {
					color: #222222;
					font-size: 28px;
					margin-top: 32px;
					.desc-item {
						display: flex;
						justify-content: center;
						flex-wrap: wrap;
						margin: 5px 0;
						line-height: 36px;
						font-size: 14px;
						cursor: pointer;
						.desc2{
							margin-left: 8px;
							font-size: 14px;
						}
					}
				}
					
				.mean-btn {
					color: #51D9A5;
					font-size: 14px;
					margin-top: 32px;
				}
					
				.linsten-btn-checked {
					background: #51D9A5 !important;
				}
					
				.linsten-btn {
					border-radius: 40px;
					border: 1px solid #E0E0E0;
					background: #FFF;
					box-shadow: 0px 4px 5.2px 0px rgba(0, 0, 0, 0.07);
					height:58px;
					width: 118px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					img {
						width: 18px;
						height: 18px;
					}
				}
			}
			.submit-btn {
				width: 120px;
				height: 40px;
				display: flex;
				align-items: center;
				margin-top: 38px;
				justify-content: center;
				background: #51D9A5;
				border: solid 1px #51D9A5;
				box-sizing: border-box;
				font-size: 14px;
				color: #fff;
				border-radius: 8px;
				cursor: pointer;
			}
		}
	}
</style>